<template>
  <div class="wrap">
    <div class="head">
      <img
        src="../../assets/images/logo.png"
        alt=""
        width="299px"
        height="53px"
        @click="jumpTo('/')"
        style="cursor: pointer;"
      />
      <p
        style="flex-grow: 1; display: flex; font-size: 22px; margin-left: 20px"
      >
        {{ title }}
      </p>
      <el-link
        icon="el-icon-s-home"
        style="display: flex; justify-content: flex-end"
        @click="backHome"
        >返回首页</el-link
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    this.getTitle();
  },
  methods: {
    getTitle() {
      if (this.$route.path == "/login") {
        this.title = "会员登录";
      } else if (this.$route.path == "/register") {
        this.title = "会员注册";
      } else if (this.$route.path == "/forget") {
        this.title = "忘记密码";
      } else if (this.$route.path.indexOf("/userCenter") != -1) {
        this.title = "用户中心";
      }
    },
    backHome() {
      this.$router.push("/");
    },
    jumpTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.wrap {
  background: white;
}
.head {
  width: 1200px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 113px;
  align-items: center;
}
.head > img {
  padding-right: 20px;
  border-right: 1px solid #f0f0f0;
}
</style>
<template>
  <div>
    <Top :data="compName" />
    <Top1 />
    <Breadcrumb />

    <el-row class="tac">
      <el-col :span="4">
        <el-menu
          :default-active="computedPath"
          class="el-menu-vertical-demo"
          @select="handleSelect"
          active-text-color="#f6ab01"
        >
          <el-menu-item index="/userCenter">
            <i class="el-icon-user-solid"></i>
            <span slot="title">个人中心</span>
          </el-menu-item>

          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>订单管理</span>
            </template>

            <el-menu-item-group>
              <el-menu-item index="/userCenter/orderlist"
                >我的订单</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>账户设置</span>
            </template>

            <el-menu-item-group>
              <el-menu-item index="/userCenter/profile">个人信息</el-menu-item>
              <el-menu-item index="/userCenter/companyProfile"
                >企业信息</el-menu-item
              >
              <el-menu-item index="/userCenter/account">账户安全</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="20">
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Top1 from "@/components/login/Top1.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  components: {
    Top,
    Top1,
    Breadcrumb,
  },
  data() {
    return {
      compName: "userCenterTop",
      navList: [
        { path: "/userCenter", name: "个人中心" },
        { path: "/userCenter/orderlist", name: "订单管理" },
        { path: "/userCenter/account", name: "账户设置" },
      ],
    };
  },
  mounted() {},
  methods: {
    handleSelect(key) {
      if (this.$route.path != key) {
        this.$router.push(key);
      }
    },
  },
  computed: {
    computedPath() {
      if (this.$route.path.indexOf("userCenter/account") != -1) {
        return "/userCenter/account";
      } else {
        return this.$route.path;
      }
    },
  },
};
</script>

<style scoped>
.tac {
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
</style>
<template>
  <div>
    <!-- 1 -->
    <UserProfile />

    <!-- 2 -->
    <el-card
      shadow="hover"
      class="box-card"
      style="margin-left: 20px; margin-bottom: 20px"
    >
      <div slot="header" class="clearfix" style="text-align: left">
        <span>我的订单</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="toList('')"
          >全部订单</el-button
        >
      </div>
      <div class="itemWrap">
        <el-badge
          :value="item.quantity"
          class="item"
          v-for="(item, i) in countOrderList"
          :key="i"
        >
          <!-- el-icon-shopping-cart-full icon -->
          <i
            :class="computedClassName(item.status)"
            style="font-size: 50px"
            @click="toList(item.status)"
          ></i>
          <el-link @click="toList(item.status)">{{
            item.order_status_describe
          }}</el-link>
        </el-badge>
      </div>
    </el-card>

    <!-- 3 -->

    <Detail :isHead="0" />
  </div>
</template>

<script>
import UserProfile from "@/components/userCenter/UserProfile.vue";
import Detail from "@/components/userCenter/orderlist/Detail.vue";
export default {
  data() {
    return {
      countOrderList: [
        {
          status: "13",
          order_status_describe: "待支付",
          quantity: 0,
        },
        {
          status: "5",
          order_status_describe: "已支付",
          quantity: 0,
        },
        {
          status: "10",
          order_status_describe: "已出单",
          quantity: 0,
        },
        {
          status: "11",
          order_status_describe: "保障中",
          quantity: 0,
        },
        {
          status: "12",
          order_status_describe: "已失效",
          quantity: 0,
        },
      ],
    };
  },
  mounted() {
    this.getCountOrder();
  },
  components: {
    UserProfile,
    Detail,
  },
  methods: {
    getCountOrder() {
      this.axios({
        url: "/api/v1/order/countOrder",
        method: "post",
        data: {
          user_code: this.$store.state.userCode,
        },
      }).then((res) => {
        if (res.data.code > 0) {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        } else {
          if (res.data.result.length > 0) {
            var arr = res.data.result;
            for (var i = 0; i < arr.length; i++) {
              for (var j = 0; j < this.countOrderList.length; j++) {
                if (this.countOrderList[j].status == arr[i].status) {
                  this.countOrderList[j].quantity = arr[i].quantity;
                }
              }
            }
          }
        }
      });
    },
    toList(n) {
      this.$router.push(`/userCenter/orderlist?p=1&ps=10&policy_no=&proposal_no=&status=${n}`);
    },
  },
  computed: {
    computedClassName() {
      return (status) => {
        if (status ==13) {
          //待支付
          return "el-icon-shopping-cart-full icon";
        } else if (status == 5) {
          //已支付
          return "el-icon-check icon";
        } else if (status == 10) {
          //已出单
          return "el-icon-download icon";
        } else if (status == 11) {
          //保障中
          return "el-icon-view icon";
        } else if (status == 12) {
          //已失效
          return "el-icon-c-scale-to-original icon";
        }
      };
    },
  },
};
</script>

<style scoped>
.itemWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  height: 130px;
}
.item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 60px;
}
.icon:hover {
  color: #409eff;
}
.icon {
  cursor: pointer;
}
</style>
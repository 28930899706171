<template>
  <el-card class="box-card" shadow="hover">
    <div slot="header" style="text-align: left">
      <span>企业信息</span>
    </div>
    <div class="item">
      <el-steps
        :space="400"
        :active="activeStep"
        finish-status="success"
        :align-center="true"
        process-status="success"
      >
        <el-step title="填写企业信息"></el-step>
        <el-step title="等待审核结果"></el-step>
        <el-step title="审核成功"></el-step>
      </el-steps>

      <el-divider></el-divider>
      <div v-if="this.is_attestation == 1" class="successAlert">认证通过</div>
      <div v-if="this.is_attestation == 2" class="successAlert">未认证</div>
      <div v-if="this.is_attestation == 3" class="successAlert">认证中...</div>
      <div v-if="this.is_attestation == 4" class="successAlert">认证未通过</div>

      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="220px"
        style="width: 600px; margin: 20px auto"
        :disabled="is_attestation == 3"
      >
        <p style="text-align: left; font-size: 18px; color: green">
          企业基本信息
        </p>

        <el-form-item label="企业名称: ">
          <el-input v-model="ruleForm.org_name"></el-input>
        </el-form-item>

        <el-form-item label="企业地址: " style="text-align: left">
          <el-cascader
            ref="org_p_c_a_name"
            size="large"
            :options="addressOptions"
            v-model="ruleForm.org_p_c_a_code"
            @change="handleChangeOrg_p_c_a_name"
            style="margin-bottom: 10px"
          >
          </el-cascader>
          <el-input
            v-model="ruleForm.org_address"
            placeholder="详细地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="经营性质: " style="text-align: left">
          <el-cascader
            ref="business_nature_des"
            placeholder="请选择"
            v-model="ruleForm.business_nature"
            :options="businessTypeOptions"
            @change="handleChangeBusiness_nature_des"
            expandTrigger="hover"
            :props="{ value: 'value_code', label: 'element_values' }"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="主要经营范围: ">
          <el-input
            v-model="ruleForm.business_scope"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="行业领域: " style="text-align: left">
          <el-cascader
            ref="industry_des"
            v-model="ruleForm.industry"
            :options="businessRangeOptions"
            @change="handleChangeIndustry_des"
            expandTrigger="hover"
            :props="{ value: 'value_code', label: 'element_values' }"
          ></el-cascader>
        </el-form-item>







        <p style="text-align: left; font-size: 18px; color: green">
          企业联系人信息
        </p>
        <el-form-item label="联系人姓名: ">
          <el-input v-model="ruleForm.link_name"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话: ">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>

        <el-form-item label="联系人地址: " style="text-align: left">
          <el-cascader
            ref="link_p_c_a_name"
            size="large"
            :options="addressOptions"
            v-model="ruleForm.link_p_c_a_code"
            @change="handleChangeLink_p_c_a_name"
            style="margin-bottom: 10px"
          >
          </el-cascader>
          <el-input
            v-model="ruleForm.link_address"
            placeholder="详细地址"
          ></el-input>
        </el-form-item>

        <p style="text-align: left; font-size: 18px; color: green">
          上传认证资料
        </p>

        <!-- bl -->
        <el-form-item label="营业执照码: " v-if="bl">
          <el-input v-model="ruleForm.user_code"></el-input>
        </el-form-item>
        <el-form-item label="上传营业执照: " style="text-align: left" v-if="bl">
          <el-upload
            name="bl"
            :headers="computedHeaders"
            class="upload-demo"
            :action="computedBaseURl + '/api/v1/batchUpload'"
            :on-success="blUploadSuccess"
            :on-remove="blhandleRemove"
            :on-error="UploadError"
            :before-upload="BeforeUpload"
            :file-list="blFileList"
            :on-change="handleChangeBl"
          >
            <img v-if="blImageUrl" :src="blImageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过2m
            </div>
          </el-upload>
        </el-form-item>

        <!-- fsl -->
        <el-form-item label="烟花爆竹经营(零售)许可证码: " v-if="fsl">
          <el-input v-model="ruleForm.fslCode"></el-input>
        </el-form-item>
        <el-form-item
          label="上传烟花爆竹经营(零售)许可证: "
          style="text-align: left"
          v-if="fsl"
        >
          <el-upload
            name="fsl"
            :headers="computedHeaders"
            class="upload-demo"
            :action="computedBaseURl + '/api/v1/batchUpload'"
            :on-success="fslUploadSuccess"
            :on-error="UploadError"
            :before-upload="BeforeUpload"
            :file-list="fslFileList"
            :on-change="handleChangeFsl"
            :on-remove="fslhandleRemove"
          >
            <img v-if="fslImageUrl" :src="fslImageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过2m
            </div>
          </el-upload>
        </el-form-item>

        <!-- fbl -->
        <el-form-item label="烟花爆竹经营(批发)许可证码: " v-if="fbl">
          <el-input v-model="ruleForm.fblCode"></el-input>
        </el-form-item>
        <el-form-item
          label="上传烟花爆竹经营(批发)许可证: "
          style="text-align: left"
          v-if="fbl"
        >
          <el-upload
            :headers="computedHeaders"
            name="fbl"
            class="upload-demo"
            :action="computedBaseURl + '/api/v1/batchUpload'"
            :on-success="fblUploadSuccess"
            :on-error="UploadError"
            :before-upload="BeforeUpload"
            :file-list="fblFileList"
            :on-change="handleChangeFbl"
            :on-remove="fblhandleRemove"
          >
            <img v-if="fblImageUrl" :src="fblImageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过2m
            </div>
          </el-upload>
        </el-form-item>

        <!-- hcbl -->
        <el-form-item label="危险化学品经营许可证码: " v-if="hcbl">
          <el-input v-model="ruleForm.hcblCode"></el-input>
        </el-form-item>
        <el-form-item
          label="上传危险化学品经营许可证: "
          style="text-align: left"
          v-if="hcbl"
        >
          <el-upload
            :headers="computedHeaders"
            name="hcbl"
            class="upload-demo"
            :action="computedBaseURl + '/api/v1/batchUpload'"
            :on-success="hcblUploadSuccess"
            :on-error="UploadError"
            :before-upload="BeforeUpload"
            :file-list="hcblFileList"
            :on-change="handleChangeHcbl"
            :on-remove="hcblhandleRemove"
          >
            <img v-if="hcblImageUrl" :src="hcblImageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过2m
            </div>
          </el-upload>
        </el-form-item>

        <!-- spl -->
        <el-form-item label="安全生产许可证码: " v-if="spl">
          <el-input v-model="ruleForm.splCode"></el-input>
        </el-form-item>
        <el-form-item
          label="上传安全生产许可证: "
          style="text-align: left"
          v-if="spl"
        >
          <el-upload
            :headers="computedHeaders"
            name="spl"
            class="upload-demo"
            :action="computedBaseURl + '/api/v1/batchUpload'"
            :on-success="splUploadSuccess"
            :on-error="UploadError"
            :before-upload="BeforeUpload"
            :file-list="splFileList"
            :on-change="handleChangeHSpl"
            :on-remove="splhandleRemove"
          >
            <img v-if="splImageUrl" :src="splImageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过2m
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit('ruleForm')"
            v-if="is_attestation != 3"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import { regionData } from "element-china-area-data";
export default {
  data() {
    return {
      blImageUrl: "",
      fslImageUrl: "",
      fblImageUrl: "",
      hcblImageUrl: "",
      splImageUrl: "",
      is_attestation: 2,
      addressOptions: regionData, //地址
      activeStep: 0,
      ruleForm: {
        RESUBTOKEN: "",
        link_name: "",
        org_name: "",
        mobile: "",
        org_p_c_a_code: "", //toString
        org_p_c_a_name: "",
        org_address: "",
        industry: "", //toString
        industry_des: "",
        business_nature: "", //toString
        business_nature_des: "",
        business_scope: "",
        link_p_c_a_code: "", //toString
        link_p_c_a_name: "",
        link_address: "",
        user_code: "",
        fslCode: "",
        fblCode: "",
        hcblCode: "",
        splCode: "",
        upload_file: [],
      },
      bl: false,
      fsl: false,
      fbl: false,
      hcbl: false,
      spl: false,
      rules: {},
      businessTypeOptions: [],

      businessRangeOptions: [],

      blFileList: [],
      fslFileList: [],
      fblFileList: [],
      hcblFileList: [],
      splFileList: [],
    };
  },
  mounted() {
    this.getOptions1();
    this.getOptions2();

    this.getUserDetail();
  },
  methods: {
    getUserDetail() {
      if (this.$store.state.userCode) {
        this.axios({
          url: "/api/v1/user/userDetails",
          method: "post",
          data: {
            user_code: this.$store.state.userCode,
          },
        }).then((res) => {
          if (res.data.code == 0) {
            if (res.data.result.is_attestation == 1) {
              //认证通过；
              this.activeStep = 2;
              this.is_attestation = 1;
              this.backFill(res);
              //data back fill
              // can edit
            } else if (res.data.result.is_attestation == 2) {
              //未认证 0；
              this.activeStep = 0;
              this.is_attestation = 2;
              //数据 默认值 空
              //can edit

              this.backFill(res);
            } else if (res.data.result.is_attestation == 3) {
              //认证中 1；
              this.activeStep = 1;
              this.is_attestation = 3;
              this.backFill(res);
              //数据只显示 不能修改提交
            } else if (res.data.result.is_attestation == 4) {
              //认证未通过' 3, 重新认证
              this.activeStep = 0;
              this.is_attestation = 4;
              this.$message({
                message: "你好! 你的企业认证没有通过,请修改资料再重新的提交",
                type: "warning",
              });
              this.backFill(res);
            }
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          }
        });
      } else {
        this.$message({
          message: "token过期请重新登录",
          type: "warning",
        });
        //delete login
        this.$store.dispatch("isLogin", "").then(() => {
          this.$Cookies.remove("isLogin");
        });
        this.$store.dispatch("userCode", "").then(() => {
          this.$Cookies.remove("userCode");
        });
        this.$store.dispatch("name", "").then(() => {
          this.$Cookies.remove("name");
        });
        this.$store.dispatch("token", "").then(() => {
          this.$Cookies.remove("token");
          this.$router.push("/login");
        });
      }
    },
    backFill(res) {
      console.log(res, "res");
      this.ruleForm.org_name = res.data.result.com_name;
      this.ruleForm.org_p_c_a_code =
        `${res.data.result.province},${res.data.result.city},${res.data.result.area}`.split(
          ","
        );
      this.ruleForm.org_p_c_a_name = res.data.result.p_c_a_ch.replace(
        /\-/g,
        ","
      );
      this.ruleForm.org_address = res.data.result.address;

      this.ruleForm.business_nature = res.data.result.unit_nature
        ? res.data.result.unit_nature.split(",")
        : "";

      this.ruleForm.business_nature_des = res.data.result.unit_nature_value;

      this.ruleForm.business_scope = res.data.result.business_scope;
      this.ruleForm.industry = res.data.result.industry.split(",");
      this.ruleForm.industry_des = res.data.result.industry_value;

      this.ruleForm.link_name = res.data.result.link_name;
      this.ruleForm.mobile = res.data.result.link_phone;

      this.ruleForm.link_p_c_a_code =
        `${res.data.result.link_province},${res.data.result.link_city},${res.data.result.link_area}`.split(
          ","
        );
      this.ruleForm.link_p_c_a_name = res.data.result.link_p_c_a_ch;
      this.ruleForm.link_address = res.data.result.link_address;

      var arr = res.data.result.paperwork_info_list;
      this.ruleForm.upload_file = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].type == "bl") {
          this.ruleForm.user_code = arr[i].value;
          this.blFileList = [{ url: arr[i].file_url, name: arr[i].name }];
          this.ruleForm.upload_file.push({
            file_type: "bl",
            name: arr[i].name,
            file_code: arr[i].code,
          });
          this.blImageUrl = arr[i].file_url;
        } else if (arr[i].type == "fsl") {
          this.ruleForm.fslCode = arr[i].value;
          this.fslFileList = [{ url: arr[i].file_url, name: arr[i].name }];
          this.ruleForm.upload_file.push({
            file_type: "fsl",
            name: arr[i].name,
            file_code: arr[i].code,
          });
          this.fslImageUrl = arr[i].file_url;
        } else if (arr[i].type == "fbl") {
          this.ruleForm.fblCode = arr[i].value;
          this.fblFileList = [{ url: arr[i].file_url, name: arr[i].name }];
          this.ruleForm.upload_file.push({
            file_type: "fbl",
            name: arr[i].name,
            file_code: arr[i].code,
          });
          this.fblImageUrl = arr[i].file_url;
        } else if (arr[i].type == "hcbl") {
          this.ruleForm.hcblCode = arr[i].value;
          this.hcblFileList = [{ url: arr[i].file_url, name: arr[i].name }];
          this.ruleForm.upload_file.push({
            file_type: "hcbl",
            name: arr[i].name,
            file_code: arr[i].code,
          });
          this.hcblImageUrl = arr[i].file_url;
        } else if (arr[i].type == "spl") {
          this.ruleForm.splCode = arr[i].value;
          this.splFileList = [{ url: arr[i].file_url, name: arr[i].name }];
          this.ruleForm.upload_file.push({
            file_type: "spl",
            name: arr[i].name,
            file_code: arr[i].code,
          });
          this.splImageUrl = arr[i].file_url;
        }
      }
    },
    handleChangeOrg_p_c_a_name(e) {
      var org_p_c_a_name = this.$refs.org_p_c_a_name.getCheckedNodes();
      this.ruleForm.org_p_c_a_name = org_p_c_a_name[0].pathLabels.toString();
    },
    handleChangeIndustry_des() {
      var industry_des = this.$refs.industry_des.getCheckedNodes();
      this.ruleForm.industry_des = industry_des[0].pathLabels.toString();
    },
    handleChangeBusiness_nature_des() {
      var business_nature_des =
        this.$refs.business_nature_des.getCheckedNodes();
      this.ruleForm.business_nature_des =
        business_nature_des[0].pathLabels.toString();
    },
    handleChangeLink_p_c_a_name() {
      var link_p_c_a_name = this.$refs.link_p_c_a_name.getCheckedNodes();
      this.ruleForm.link_p_c_a_name = link_p_c_a_name[0].pathLabels.toString();
    },
    onSubmit(ref) {
      if (
        (this.fslImageUrl == "" && this.fsl) ||
        (this.blImageUrl == "" && this.bl) ||
        (this.fblImageUrl == "" && this.fbl) ||
        (this.hcblImageUrl == "" && this.hcbl) ||
        (this.splImageUrl == "" && this.spl) ||
        (this.ruleForm.user_code == "" && this.bl) ||
        (this.ruleForm.fslCode == "" && this.fsl) ||
        (this.ruleForm.fblCode == "" && this.fbl) ||
        (this.ruleForm.hcblCode == "" && this.hcbl) ||
        (this.ruleForm.splCode == "" && this.spl)
      ) {
        this.$message({
          message: "请填写完整审核信息",
          type: "warning",
        });
        return;
      }

      this.axios({
        url: "/api/v1/user/resubToken",
      }).then((res) => {
        this.ruleForm.RESUBTOKEN = res.data;

        if (this.activeStep == 0 || this.activeStep == 2) {
          for (var i = 0; i < this.ruleForm.upload_file.length; i++) {
            if (this.ruleForm.upload_file[i].file_type == "bl") {
              this.ruleForm.upload_file[i].value = this.ruleForm.user_code;
            } else if (this.ruleForm.upload_file[i].file_type == "fsl") {
              this.ruleForm.upload_file[i].value = this.ruleForm.fslCode;
            } else if (this.ruleForm.upload_file[i].file_type == "fbl") {
              this.ruleForm.upload_file[i].value = this.ruleForm.fblCode;
            } else if (this.ruleForm.upload_file[i].file_type == "hcbl") {
              this.ruleForm.upload_file[i].value = this.ruleForm.hcblCode;
            } else if (this.ruleForm.upload_file[i].file_type == "spl") {
              this.ruleForm.upload_file[i].value = this.ruleForm.splCode;
            }
          }
          var arr = [...this.ruleForm.upload_file];

          for (var i = 0; i < arr.length; i++) {
            if (arr[i].file_type == "bl" && !this.bl) {
              delete arr[i];
            } else if (arr[i].file_type == "fsl" && !this.fsl) {
              delete arr[i];
            } else if (arr[i].file_type == "fbl" && !this.fbl) {
              delete arr[i];
            } else if (arr[i].file_type == "hcbl" && !this.hcbl) {
              delete arr[i];
            } else if (arr[i].file_type == "spl" && !this.spl) {
              delete arr[i];
            }
          }
          arr = arr.filter(function (e) {
            return e;
          });

          this.axios({
            method: "post",
            url: "/api/v1/enterprise/subCertification",
            data: {
              ...this.ruleForm,
              upload_file: arr,
              org_p_c_a_code: this.ruleForm.org_p_c_a_code.toString(),
              industry: this.ruleForm.industry.toString(),
              business_nature: this.ruleForm.business_nature.toString(),
              link_p_c_a_code: this.ruleForm.link_p_c_a_code.toString(),
              user_code: this.$store.state.userCode,
            },
          }).then((res) => {
            if (res.data.code > 0) {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$router.go(0);
            }
          });
        }
      });
    },
    handleChangeBl(file, fileList) {
      this.blFileList = [];
      this.blFileList.push(file);
    },
    handleChangeFsl(file, fileList) {
      this.fslFileList = [];
      this.fslFileList.push(file);
    },
    handleChangeFbl(file, fileList) {
      this.fblFileList = [];
      this.fblFileList.push(file);
    },
    handleChangeHcbl(file, fileList) {
      this.hcblFileList = [];
      this.hcblFileList.push(file);
    },
    handleChangeHSpl(file, fileList) {
      this.splFileList = [];
      this.splFileList.push(file);
    },
    /* upload bl */
    blUploadSuccess(res, file, fileList) {
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });

        this.blImageUrl = URL.createObjectURL(file.raw);
        
        if (this.ruleForm.upload_file.length == 0) {
          this.ruleForm.upload_file.push({
            file_type: "bl",
            name: res.result[0].file_name,
            file_code: res.result[0].file_code,
          });
        } else {
          var f = false;
          for (var i = 0; i < this.ruleForm.upload_file.length; i++) {
            if (this.ruleForm.upload_file[i].file_type == "bl") {
              this.ruleForm.upload_file[i].name = res.result[0].file_name;
              this.ruleForm.upload_file[i].file_code = res.result[0].file_code;
              f = true;
            }
          }
          if (!f) {
            this.ruleForm.upload_file.push({
              file_type: "bl",
              name: res.result[0].file_name,
              file_code: res.result[0].file_code,
            });
          }
        }
      }
    },
    BeforeUpload(file) {
      if (
        file.type.indexOf("png") == -1 &&
        file.type.indexOf("jpg") == -1 &&
        file.type.indexOf("jpeg") == -1
      ) {
        this.$message({
          message: "上传文件格式只能png或jpg",
          type: "warning",
        });
        return false;
      } else if (file.size > 2000000) {
        this.$message({
          message: "上传文件大小不超过2M",
          type: "warning",
        });
        return false;
      }
    },
    Exceed(files, fileList) {
      this.$message({
        message: "上传文件不能超过1个",
        type: "warning",
      });
    },
    /* upload fsl */
    fslUploadSuccess(res, file, fileList) {
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.fslImageUrl = URL.createObjectURL(file.raw);

        if (this.ruleForm.upload_file.length == 0) {
          this.ruleForm.upload_file.push({
            file_type: "fsl",
            name: res.result[0].file_name,
            file_code: res.result[0].file_code,
          });
        } else {
          var f = false;
          for (var i = 0; i < this.ruleForm.upload_file.length; i++) {
            if (this.ruleForm.upload_file[i].file_type == "fsl") {
              this.ruleForm.upload_file[i].name = res.result[0].file_name;
              this.ruleForm.upload_file[i].file_code = res.result[0].file_code;
              f = true;
            }
          }
          if (!f) {
            this.ruleForm.upload_file.push({
              file_type: "fsl",
              name: res.result[0].file_name,
              file_code: res.result[0].file_code,
            });
          }
        }
      }
    },

    /*  fbl  */
    fblUploadSuccess(res, file, fileList) {
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.fblImageUrl = URL.createObjectURL(file.raw);
        if (this.ruleForm.upload_file.length == 0) {
          this.ruleForm.upload_file.push({
            file_type: "fbl",
            name: res.result[0].file_name,
            file_code: res.result[0].file_code,
          });
        } else {
          var f = false;
          for (var i = 0; i < this.ruleForm.upload_file.length; i++) {
            if (this.ruleForm.upload_file[i].file_type == "fbl") {
              this.ruleForm.upload_file[i].name = res.result[0].file_name;
              this.ruleForm.upload_file[i].file_code = res.result[0].file_code;
              f = true;
            }
          }
          if (!f) {
            this.ruleForm.upload_file.push({
              file_type: "fbl",
              name: res.result[0].file_name,
              file_code: res.result[0].file_code,
            });
          }
        }
      }
    },

    /* hcbl */
    hcblUploadSuccess(res, file, fileList) {
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.hcblImageUrl = URL.createObjectURL(file.raw);
        if (this.ruleForm.upload_file.length == 0) {
          this.ruleForm.upload_file.push({
            file_type: "hcbl",
            name: res.result[0].file_name,
            file_code: res.result[0].file_code,
          });
        } else {
          var f = false;
          for (var i = 0; i < this.ruleForm.upload_file.length; i++) {
            if (this.ruleForm.upload_file[i].file_type == "hcbl") {
              this.ruleForm.upload_file[i].name = res.result[0].file_name;
              this.ruleForm.upload_file[i].file_code = res.result[0].file_code;
              f = true;
            }
          }

          if (!f) {
            this.ruleForm.upload_file.push({
              file_type: "hcbl",
              name: res.result[0].file_name,
              file_code: res.result[0].file_code,
            });
          }
        }
      }
    },

    /* spl */
    splUploadSuccess(res, file, fileList) {
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.splImageUrl = URL.createObjectURL(file.raw);
        if (this.ruleForm.upload_file.length == 0) {
          this.ruleForm.upload_file.push({
            file_type: "spl",
            name: res.result[0].file_name,
            file_code: res.result[0].file_code,
          });
        } else {
          var f = false;
          for (var i = 0; i < this.ruleForm.upload_file.length; i++) {
            if (this.ruleForm.upload_file[i].file_type == "spl") {
              this.ruleForm.upload_file[i].name = res.result[0].file_name;
              this.ruleForm.upload_file[i].file_code = res.result[0].file_code;
              f = true;
            }
          }
          if (!f) {
            this.ruleForm.upload_file.push({
              file_type: "spl",
              name: res.result[0].file_name,
              file_code: res.result[0].file_code,
            });
          }
        }
      }
    },

    UploadError(err, file, fileList) {
      this.$message.warning(err);
    },

    getOptions1() {
      this.axios.get(`/api/v1/enterprise/getElement?type=1`).then((res) => {
        this.businessRangeOptions = res.data.result;
      });
    },
    getOptions2() {
      this.axios.get(`/api/v1/enterprise/getElement?type=2`).then((res) => {
        this.businessTypeOptions = res.data.result;
      });
    },
    blhandleRemove(file, fileList) {
      this.blImageUrl = "";
    },
    fslhandleRemove(file, fileList) {
      this.fslImageUrl = "";
    },
    fblhandleRemove(file, fileList) {
      this.fblImageUrl = "";
    },
    hcblhandleRemove(file, fileList) {
      this.hcblImageUrl = "";
    },
    splhandleRemove(file, fileList) {
      this.splImageUrl = "";
    },
  },
  watch: {
    ///api/v1/enterprise/getDocuments?industry_kind=M13,M1302,M130201,M13020102
    //get
    "ruleForm.industry": {
      handler(n, o) {
        if (n.toString()) {
          this.axios({
            url: "/api/v1/enterprise/getDocuments",
            method: "get",
            params: { industry_kind: n.toString() },
          }).then((res) => {
            this.bl = false;
            this.fsl = false;
            this.fbl = false;
            this.hcbl = false;
            this.spl = false;
            for (var i = 0; i < res.data.result.length; i++) {
              this[res.data.result[i].code] = true;
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    computedHeaders() {
      return {
        Authorization: `Bearer ${this.$store.state.token}`,
      };
    },
    computedBaseURl() {
      return this.$store.state.baseURL;
    },
  },
};
</script>

<style scoped>
.box-card {
  text-align: left;
  margin-left: 20px;
}
.item {
  text-align: center;
}

.codeWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.successAlert {
  margin: 10px auto;
  height: 100px;
  color: #e6a23c;
  line-height: 100px;
  font-size: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border: 1px #dcdfe6 solid;
  margin-right: 20px;
}
.el-icon-plus {
  border: 1px #dcdfe6 solid;
  margin-right: 20px;
}
</style>
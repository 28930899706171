<template>
  <el-card class="box-card" shadow="hover">
    <div slot="header" class="clearfix">
      <span>修改密码</span>
    </div>
    <div class="item">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-width="120px"
        class="formWrap"
        :rules="rules"
      >
        <el-form-item label="账户手机号: ">
          <el-input v-model="ruleForm.name" disabled></el-input>
        </el-form-item>

        <div class="codeWrap">
          <el-form-item label="验证码: " prop="code" class="codeInput">
            <el-input
              v-model="ruleForm.code"
              placeholder="验证码"
              prefix-icon="el-icon-document-checked"
            ></el-input>
          </el-form-item>
          <div
            style="
              width: 120px;
              height: 40px;
              cursor: pointer;
              margin-left: 20px;
            "
          >
            <img
              :src="computedBaseURl + '/api/v1/util/imgCaptcha'"
              alt=""
              width="auto"
              height="100%"
              @click="refreshImg"
              ref="verifyImg"
            />
          </div>
        </div>

        <!-- phonecode -->
        <div class="codeWrap">
          <el-form-item
            label="动态码: "
            prop="phoneCode"
            class="phoneCodeInput"
          >
            <el-input
              v-model="ruleForm.phoneCode"
              placeholder="动态码"
              prefix-icon="el-icon-phone-outline"
              autoComplete="off"
            ></el-input>
          </el-form-item>
          <el-button
            type="primary"
            plain
            style="
              width: 100px;
              height: 42px;
              padding-right: 0;
              padding-left: 0;
              margin-left: 20px;
            "
            @click="getPhoneCode"
            :disabled="disabledBtn"
            v-loading="loading"
            element-loading-text="请查看手机"
            element-loading-spinner="el-icon-loading"
            >获取动态码</el-button
          >
        </div>
        <div class="codeWrap">
          <el-form-item label="密码: " prop="password1" class="phoneCodeInput">
            <el-input
              v-model="ruleForm.password1"
              placeholder="密码"
              prefix-icon="el-icon-orange"
              type="password"
            ></el-input>
          </el-form-item>
        </div>
        <div class="codeWrap">
          <el-form-item
            label="确认密码: "
            prop="password2"
            class="phoneCodeInput"
          >
            <el-input
              v-model="ruleForm.password2"
              placeholder="确认密码"
              prefix-icon="el-icon-orange"
              type="password"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item>
          <el-button type="primary" @click="onSubmit('ruleForm')"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    var validatePassword2 = (rule, value, callback) => {
      if (
        this.ruleForm.password2.length >= 8 &&
        this.ruleForm.password2.length <= 20
      ) {
        if (
          this.ruleForm.password2.search(/[a-zA-Z]/) == -1 ||
          this.ruleForm.password2.search(/[0-9]/) == -1 ||
          this.ruleForm.password2.search(/[\W\_]/) == -1
        ) {
          callback(new Error("密码需要包含数字,字母,符号的三种组合"));
        } else if (this.ruleForm.password2 != this.ruleForm.password1) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      } else {
        callback(new Error("密码位数需要8-20位之间"));
      }
    };
    return {
      time: "",
      loading: false,
      disabledBtn: false,
      ruleForm: {
        name: "",
        code: "",
        phoneCode: "",
        password1: "",
        password2: "",
      },
      rules: {
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 1, max: 4, message: "长度在 4 个字符", trigger: "blur" },
        ],
        phoneCode: [
          { required: true, message: "请输入动态码", trigger: "blur" },
          { min: 1, max: 6, message: "输入太长了", trigger: "blur" },
        ],
        password1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 20,
            message: "密码长度在 8 到 20 个字符",
            trigger: "blur",
          },
        ],
        password2: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 20,
            message: "密码长度在 8 到 20 个字符",
            trigger: "blur",
          },
          {
            validator: validatePassword2,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.refreshImg();
  },
  methods: {
    onSubmit(ref) {
      if (this.ruleForm.code && this.ruleForm.phoneCode) {
        this.axios({
          url: "/api/v1/user/verifyIdentidy",
          method: "post",
          data: {
            name: this.ruleForm.name,
            code: this.ruleForm.code,
            phoneCode: this.ruleForm.phoneCode,
            sms_flag: "0",
          },
        }).then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          } else {
            this.$refs["ruleForm"].validate((valid) => {
              if (valid) {
                this.axios({
                  url: "/api/v1/user/changePassword",
                  method: "post",
                  data: {
                    name: this.ruleForm.name,
                    new_password: this.md5(this.ruleForm.password1.trim()),
                    phoneCode: this.ruleForm.phoneCode,
                  },
                }).then((res) => {
                  if (res.data.code > 0) {
                    this.$message({
                      message: res.data.msg,
                      type: "error",
                    });
                  } else {
                    this.$message({
                      message: res.data.msg,
                      type: "success",
                    });
                    setTimeout(() => {
                      this.$router.push("/userCenter");
                    }, 1000);
                  }
                });
              }
            });
          }
        });
      } else {
        this.$message({
          message: "请输入内容",
          type: "error",
        });
      }
    },
    refreshImg() {
      var time = new Date().getTime();
      this.time = time;
      this.$refs.verifyImg.src =
        this.computedBaseURl + `/api/v1/util/imgCaptcha?timestamp=` + this.time;
      this.ruleForm.code = "";
    },
    getPhoneCode() {
      if (this.ruleForm.code && this.ruleForm.code.length == 4) {
        this.loading = true;
        this.disabledBtn = true;

        this.axios
          .post("/api/v1/user/sendSms", {
            name: this.ruleForm.name,
            code: this.ruleForm.code,
            sms_flag: "0",
            img_timestamp: this.time,
          })
          .then((res) => {
            if (res.data.code > 0) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              this.loading = false;
              this.disabledBtn = false;
            } else {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              setTimeout(function () {
                this.loading = false;
                this.disabledBtn = false;
              }, 60 * 1000);
            }
          })
          .catch((res) => {
            this.$message({
              message: res,
              type: "error",
            });
            this.loading = false;
            this.disabledBtn = false;
          });
      } else {
        this.$message({
          message: "请先输入验证码",
          type: "error",
        });
      }
    },
  },
  computed: {
    computedBaseURl() {
      return this.$store.state.baseURL;
    },
  },
  watch: {
    "$store.state.name": {
      handler(n, o) {
        this.ruleForm.name = this.$store.state.name;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.box-card {
  text-align: left;
  margin-left: 20px;
}
.item {
  text-align: center;
}
.formWrap {
  width: 600px;
  margin: 20px auto;
}
.codeWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.successAlert {
  width: 500px;
  margin: 20px auto;
  height: 300px;
  color: #e6a23c;
  line-height: 300px;
  font-size: 20px;
}
</style>
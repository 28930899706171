export default {
  stateOptions: [{
      title: "全部订单",
      val: 0
    },
    {
      title: "待支付",
      val: 1
    },
    {
      title: "已支付",
      val: 2
    },
    {
      title: "已出单",
      val: 3
    },
    {
      title: "保障中",
      val: 4
    },
    {
      title: "已失效",
      val: 5
    },
    {
      title: "出单失败",
      val: 6
    },
    {
      title: "已取消",
      val: 7
    },
    {
      title: "已逾期",
      val: 8
    },
    {
      title: "已退保",
      val: 9
    },
  ],
}
<template>
  <div>
    <Detail :isHead="1" />
  </div>
</template>

<script>
import Detail from "@/components/userCenter/orderlist/Detail.vue";
export default {
  components: {
    Detail,
  },
};
</script>

<style scoped>
</style>
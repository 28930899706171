<template>
  <div>
    <el-breadcrumb separator="/" style="width: 1200px; margin: 20px auto">
      <div v-if="$route.path == '/userCenter'">
        <el-breadcrumb-item :to="{ path: '/' }">首页 </el-breadcrumb-item>
        <el-breadcrumb-item>个人中心 </el-breadcrumb-item>
      </div>
      <div v-else>
        <el-breadcrumb-item :to="{ path: '/' }">首页 </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/userCenter' }"
          >个人中心
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.meta.Breadcrumb }} </el-breadcrumb-item>
      </div>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style scoped>
</style>
<template>
  <div style="margin-left: 20px; margin-bottom: 20px">
    <el-card class="box-card" shadow="hover">
      <div class="item">
        <div class="block">
          <el-avatar
            icon="el-icon-user-solid"
            :size="70"
            style="font-size: 50px"
          ></el-avatar>
        </div>

        <div>
          <div style="font-weight: bold; font-size: 20px; margin-bottom: 10px">
            {{ userProfile.name }}
          </div>

          <div style="margin-bottom: 10px">
            <el-tag
              style="margin-right: 30px; cursor: pointer"
              :type="computedStyle"
              @click="jumpTo('/userCenter/companyProfile')"
            >
              {{ computedAttestation }}
            </el-tag>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userProfile: {
        name: "",
        safeLevel: "安全",
        phoneVerify: { val: 1, title: "手机已验证" },
        companyVerify: { val: 0, title: "企业没验证" },
      },
      is_attestation: 2,
      //'1，认证通过；2未认证；3认证中；4：认证未通过'
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    jumpTo(path) {
      this.$router.push(path);
    },
    getData() {
      //todo
      var userCode = this.$store.state.userCode;
      this.axios({
        url: "/api/v1/user/userDetails",
        method: "post",
        data: {
          user_code: userCode,
        },
      }).then((res) => {
        if (res.data.code > 0) {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        } else {
          if (res.data.result.is_attestation === null) {
            this.is_attestation = 2;
          } else {
            this.is_attestation = res.data.result.is_attestation;
          }
        }
      });
    },
  },
  computed: {
    computedAttestation() {
      if (this.is_attestation == 1) {
        return "认证通过";
      } else if (this.is_attestation == 2) {
        return "未认证";
      } else if (this.is_attestation == 3) {
        return "认证中";
      } else if (this.is_attestation == 4) {
        return "认证未通过";
      } else {
        return "未认证";
      }
    },
    computedStyle() {
      if (this.is_attestation == 1) {
        return "success";
      } else if (this.is_attestation == 2) {
        return "danger";
      } else if (this.is_attestation == 3) {
        return "info";
      } else if (this.is_attestation == 4) {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
  watch: {
    "$store.state.name": {
      handler(n, o) {
        this.userProfile.name = this.$store.state.name;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.box-card {
  text-align: left;
}
.item {
  display: flex;
}
.block {
  margin-right: 20px;
  border-right: 1px solid #ececec;
  padding-right: 20px;
}
.icon {
  margin-right: 20px;
}
</style>
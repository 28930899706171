<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>个人信息</span>
    </div>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="手机号: ">
        <el-input v-model="ruleForm.phoneNum" disabled></el-input>
      </el-form-item>

      <el-form-item label="用户真实姓名: " prop="realName">
        <el-input
          v-model="ruleForm.realName"
          placeholder="请输入真实姓名"
        ></el-input>
      </el-form-item>

      <el-form-item label="性别: ">
        <el-radio-group v-model="ruleForm.sex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="出生日期: ">
        <el-date-picker
          v-model="ruleForm.birthday"
          type="date"
          placeholder="选择出生日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="联系地址: " prop="address">
        <el-input
          v-model="ruleForm.address"
          placeholder="请输入联系地址"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确认提交</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        phoneNum: "",
        realName: "",
        sex: 1,
        birthday: "",
        address: "",
      },
      rules: {
        realName: [
          { min: 0, max: 200, message: "输入太长了", trigger: "blur" },
        ],
        address: [{ min: 0, max: 200, message: "输入太长了", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var timer = this.$moment(this.ruleForm.birthday).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.axios({
            url: "/api/v1/user/resubToken",
            method: "get",
          }).then((res) => {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.axios({
                  url: "/api/v1/user/userUpdate",
                  method: "post",
                  data: {
                    RESUBTOKEN: res.data,
                    actual_name: this.ruleForm.realName,
                    nick_name: "",
                    date_of_birth: timer,
                    contact_address: this.ruleForm.address,
                    sex: this.ruleForm.sex,
                    email: "",
                    user_code: this.$store.state.userCode,
                    phone: this.ruleForm.phoneNum,
                  },
                }).then((res) => {
                  // to do
                  //success push usercenter
                  if (res.data.code == 200) {
                    this.$message({
                      message: res.data.msg,
                      type: "success",
                    });
                    setTimeout(() => {
                      this.$router.go(0);
                    }, 1000);
                  } else {
                    this.$message({
                      message: res.data.msg,
                      type: "error",
                    });
                  }
                });
              }
            });
          });
        }
      });
    },
    getData() {

      this.axios({
        url: "/api/v1/user/userInfo",
        method: "post",
        data: {
          user_code: this.$store.state.userCode,
        },
      }).then((res) => {

        if (res.data.code > 0) {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        } else {
          this.ruleForm.sex = res.data.result.sex;
          this.ruleForm.phoneNum = this.$store.state.name;
          this.ruleForm.realName = res.data.result.actual_name
            ? res.data.result.actual_name
            : "";
          this.ruleForm.birthday = res.data.result.date_of_birth
            ? res.data.result.date_of_birth
            : "";
          this.ruleForm.address = res.data.result.contact_address
            ? res.data.result.contact_address
            : "";
        }
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  text-align: left;
  margin-left: 20px;
}
.demo-ruleForm {
  width: 500px;
  margin: 30px auto;
}
</style>
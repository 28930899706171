<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>账户安全</span>
    </div>
    <div class="item">
      <div style="display: flex; align-items: center">
        <span style="display: flex; align-items: center; width: 200px">
          <i class="el-icon-success icon"></i
          ><span
            style="
              margin-left: 10px;
              margin-right: 30px;
              color: #67c23a;
              cursor: pointer;
            "
            @click="jumpTo('/userCenter/account/modifyPass')"
            >密码修改</span
          >
        </span>
      </div>

      <el-divider></el-divider>
      <div style="display: flex; align-items: center">
        <span style="display: flex; align-items: center; width: 200px">
          <i class="el-icon-success icon"></i
          ><span
            style="
              margin-left: 10px;
              margin-right: 30px;
              color: #67c23a;
              cursor: pointer;
            "
            @click="jumpTo('/userCenter/account/bindMobile')"
            >手机号绑定</span
          >
        </span>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      accountInfo: {
        pwdLevel: "高",
        phoneNum: "13335713700",
      },
    };
  },
  methods: {
    jumpTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.box-card {
  text-align: left;
  margin-left: 20px;
}
.item {
  border: 1px solid #e4e7ed;
  padding: 20px;
  line-height: 20px;
}
.icon {
  color: #67c23a;
  font-size: 20px;
}
</style>
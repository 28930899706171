<template>
  <div>
    <el-card class="box-card" shadow="hover">
      <div slot="header" class="clearfix">
        <span>订单列表</span>
      </div>

      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        v-if="isHead == 1"
      >
        <el-form-item>
          <el-input
            v-model="formInline.no"
            placeholder="保单号/投保单号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>

      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        @select="handleSelect"
        text-color="#909399"
        active-text-color="#409EFF"
      >
        <el-menu-item index="">全部订单</el-menu-item>
        <el-menu-item index="13">待支付</el-menu-item>
        <el-menu-item index="5">已支付</el-menu-item>
        <el-menu-item index="10">已出单</el-menu-item>
        <el-menu-item index="11">保障中</el-menu-item>
        <el-menu-item index="12">已失效</el-menu-item>
        <el-menu-item index="7">出单失败</el-menu-item>
        <el-menu-item index="8">已取消</el-menu-item>
        <el-menu-item index="14">已逾期</el-menu-item>
        <el-menu-item index="9">已退保</el-menu-item>
      </el-menu>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <dl class="extraItems">
              <dt>保单名称:</dt>
              <dd>{{ props.row.product_name }}</dd>
              <dt>被保人名称:</dt>
              <dd>{{ props.row.insurer_name }}</dd>
              <dt>投保单号:</dt>
              <dd>{{ props.row.proposal_no }}</dd>
              <dt>保单号:</dt>
              <dd>{{ props.row.policy_no }}</dd>
              <dt>保险期间:</dt>
              <dd>
                {{ props.row.start_time + " 至 " + props.row.end_time }}
              </dd>
            </dl>
          </template>
        </el-table-column>

        <el-table-column label="订单号" prop="order_no" width="230px">
        </el-table-column>
        <el-table-column label="下单时间" prop="create_time" width="100px">
        </el-table-column>
        <el-table-column label="承保公司" prop="insurance_com_name">
        </el-table-column>

        <el-table-column label="保费" width="110px">
          <span slot-scope="scope" style="color: red"
            >¥ {{ scope.row.actual_prem }}</span
          >
        </el-table-column>
        <el-table-column label="状态" width="100px">
          <template slot-scope="scope">
            <el-tag type="warning">{{
              computedStatus(scope.row.status)
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170px">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >订单详情</el-button
            >
            <el-link
              style="
                font-size: 12px;
                margin-top: -2px;
                margin-left: 10px;
                color: #409EFF;
              "
              :underline="false"
              download="下载保单.pdf"
              :href="scope.row.electronic_policy"
              v-if="scope.row.electronic_policy"
              >下载保单</el-link
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :current-page="p"
        @current-change="currentChange"
        style="text-align: center; margin-top: 20px"
      >
      </el-pagination>
    </el-card>

    <Dialog1
      :isShow="isShow"
      :orderId="orderId"
      @toParent="getChildValue($event)"
    />
  </div>
</template>

<script>
import Dialog1 from "../../product/Dialog1.vue";
import cons from "@/common/cons.js";
export default {
  components: {
    Dialog1,
  },
  props: ["isHead"],
  data() {
    return {
      activeIndex: "",
      formInline: {
        no: "",
      },
      stateOptions: cons.stateOptions,
      isShow: false,
      orderId: "",
      tableData: [],

      p: 1, //"当前第几页 从1开始"
      ps: 10, //"每页条数"
      total: 0,
      policy_no: "", //保单号
      proposal_no: "", // 投保单号
      status: "",
    };
  },
  mounted() {},
  methods: {
    handleSelect(k) {
      this.status = k;
      this.getData();
    },
    onSubmit() {
      this.status = "";
      this.getData();
    },
    handleClick(row) {
      this.isShow = true;
      this.orderId = row.order_no;
    },
    currentChange(p) {
      this.p = p;
      this.getData();
    },
    getChildValue(e) {
      this.isShow = e;
    },
    getData() {
      var no = "";
      var policy_no = "";
      var proposal_no = "";
      if (this.formInline.no) {
        no = this.formInline.no[0];
        if (no == "T") {
          proposal_no = this.formInline.no;
        } else {
          policy_no = this.formInline.no;
        }
      }
      this.axios({
        url: "/api/v1/order/orderList",
        method: "get",
        params: {
          p: this.p,
          ps: this.ps,
          policy_no: policy_no,
          proposal_no: proposal_no,
          status: this.status,
        },
      }).then((res) => {
        if (res.data.code > 0) {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        } else {
          this.tableData = res.data.result.items;
          this.total = res.data.result.total;
          this.p = res.data.result.page > 0 ? res.data.result.page : 1;
        }
      });
    },
  },
  computed: {
    computedStatus() {
      return (status) => {
        if (status == 5) {
          return "已支付";
        } else if (status == 7) {
          return "出单失败";
        } else if (status == 8) {
          return "已取消";
        } else if (status == 9) {
          return "已退保";
        } else if (status == 10) {
          return "已出单";
        } else if (status == 11) {
          return "保障中";
        } else if (status == 12) {
          return "已失效";
        } else if (status == 13) {
          return "待支付";
        } else if (status == 14) {
          return "已逾期";
        } else if (status == 15) {
          return "待处理";
        }
      };
    },
  },
  watch: {
    "$route.query": {
      handler(n, o) {
        this.status = n.status;
        this.activeIndex = n.status;

        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.box-card {
  text-align: left;
  margin-left: 20px;
}
.extraItems {
  display: flex;
  flex-wrap: wrap;
}
.extraItems dt {
  width: 80px;
  color: #909399;
}
.extraItems dd {
  width: 700px;
  margin-bottom: 10px;
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
</style>